@import "../../styles/common";

.mapButton{
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  margin-bottom:1em;
  border-radius: 5px;
  width: 3.5em;
  height: 3em;
  cursor: pointer;
  transition: all 0.4s ease 0s;
}

.mapButton:hover{
  background-color: #60aef7;
  border-color: #60aef7;
}

.diagramDisplayPanel{
  display:flex;
  height:100%
}

.metadataSection{
  padding: 0.2em;
  overflow: auto;
  width:40%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  @media screen and (min-width: $big-screen-size) {
    width:25%;
  }
}

.mapSection{
  width:60%;
  margin: 0.2em;
  border-radius:10px;
  background: rgba(0, 0, 0, 0.11);
  padding:3px;
  @media screen and (min-width: $big-screen-size) {
    width:75%;
  }
}

.refreshButton {
  width: 100%;
  height: 40px;
  font-size: 1.3em;
}

.resizeMapButton {
  position: absolute;
  bottom: 3%;
  right: 1.5%
}

.diagramTitle{
  font-size: 14px;
  margin-bottom: 0.5em;
  word-break: break-all;
}

.renderingOptionsTitle{
  font-size: 24px;
  margin-top: 0.5em;
}

.optionsOverDiagram{
  font-size: 15px;
  margin: 0.5em 0 0.5em 0;
}

.backButton{
  width: 100%;
  height: 3em;
}