@import "../../styles/common";

.gud-diag-options_search_bar_section {
  display: flex;
  flex-direction: column;
  padding: 2%;
  position: sticky;
  top: 0;
  background: #bfdffd;
  z-index: 2;
  border-bottom: solid 1px #1890ff4f;
  border-radius: 4px;
  width: 98%;
  margin-left: 1%;
  @media screen and (min-width: $big-screen-size) {
    flex-direction: row;
  }
}

.gud-diag-cols-box {
  margin: 2%;
  @media screen and (min-width: $medium-screen-size) {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
  }

}

.gud-diag-options_search_label {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  font-weight: 600
}

.gud-diag-options_search_bar_button {
  flex: 5;
}

.gud-diag-options_rendering_options {
  padding-top: 2%;
  @media screen and (min-width: $medium-screen-size) {
    width: 45%;
    padding-left: 2%
  }
  @media screen and (min-width: $big-screen-size) {
    width: 40%;
    padding-left: 2%
  }
}

.gud-diag-options_rendering_options_collapsible {
  width: 100%;
  @media screen and (min-width: $big-screen-size) {
    width: 100%;
  }
}

.gud-diag-options_rendering_options_content {
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 250px
}

.gud-diag-options_rendering_options_directories,
.gud-diag-options_rendering_options_subdirectories{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.gud-diag-options_rendering_options_directories_input,
.gud-diag-options_rendering_options_subdirectories_input{
  width: 100%;
}

.gud-diag-options_rendering_options_toggle{
  margin: .5em;
}

.diagramLogo{
  width: 30px;
}

.optionsHeaderContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.optionsHeader{
  margin: 0 0 0 0.3em;
}

.recentProjectLink{
  display: flex;
  flex-direction: column;
}

.recentProject{
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
}

.recentProjectIcon{
  margin-right: 10px;
  margin-top: 1%;
}

.reposList{
  padding-bottom: 5%;
  overflow: scroll;
}

.loginButton{
  z-index: 10;
  position: absolute;
  left: 90%;
  margin-top: 0.5%;
  @media screen and (min-width: $medium-screen-size) {
    left: 95%;
  }
}